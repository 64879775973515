
import { Component, Vue } from 'vue-property-decorator';
import SearchBar from '../components/SearchBar.vue';
import AppBarEnd from './components/AppBarEnd.vue';
import AppBar from '@/ui-components/AppBar/AppBar.vue';
import AppBarStart from '@/layouts/components/AppBarStart.vue';
import SideNav from '@/layouts/components/SideNav.vue';
import namespaces from '@/store/namespaces';
import { Getter } from 'vuex-class';
import { UserRouteNames } from '@/types/users';

@Component({
  name: 'AdvertiserLayout',
  components: {
    SideNav,
    AppBarStart,
    AppBar,
    SearchBar,
    AppBarEnd,
  },
})
export default class extends Vue {
  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;

  @Getter('userRouteNames', { namespace: namespaces.AuthModule })
  public userRouteNames!: UserRouteNames;

  public get dashboardRoute() {
    return this.userRouteNames.DASHBOARD;
  }
}
